import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { User } from '../01-models';
import { map } from "rxjs/operators";
import { StorageService } from "./storage.service";

const USERS_KEY = 'users';

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
    ) {}

  getUsers(): Observable<User[]> {
    const url = `${API_URL}/${VERSION}/user/users`;
    return this.http.get<User[]>(url).pipe(
      map(users => {
        this.storageService.set(USERS_KEY, users);
        return users;
      })
    );
  }

  getUserById(userId: number): Observable<User | undefined> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.find(user => user.id == userId))
    );
  }

  getUserByEmail(email: string): Observable<User | undefined> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.find(user => user.email == email))
    );
  }

  getUserByDni(dni: string): Observable<User | undefined> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.find(user => dni && user.dni == dni.toUpperCase()))
    );
  }

  getUsersByDni(dnis: string[]): Observable<User[]> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => {
        dnis = dnis.map(dni => dni && dni.toUpperCase());
        return users.filter(user => user.dni && dnis.includes(user.dni));
      })
    );
  }

  getWorkers(): Observable<User[]> {
    return from(this.storageService.get(USERS_KEY)).pipe(
      map((users: User[]) => users.filter(user => user.role !== 'Supervisor'))
    );
  }

  update(user: User, password?: any) {
    const url = `${API_URL}/${VERSION}/user/update`;
    this.updateUser(user);
    return this.http.post(url, password ? {...user, password} : user);
  }

  updateUser(editedUser: User) {
    this.storageService.get(USERS_KEY).then((users: User[]) => {
      const index = users.findIndex(user => user.id === editedUser.id);
      users[index] = editedUser;
      this.storageService.set(USERS_KEY, users);
    });
  }

  userDelete(userId: number) {
    const url = `${API_URL}/${VERSION}/user/userDeleteLogic/${userId}`;
    this.storageService.get(USERS_KEY).then((users: User[]) => {
      const index = users.findIndex(user => user.id === userId);
      users.splice(index, 1);
      this.storageService.set(USERS_KEY, users);
    });
    return this.http.post(url, {});	
  }
}
