import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom } from "rxjs";
import { Ticket } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const TICKETS_KEY = 'tickets';
const TICKETS_IMAGE_KEY = 'tickets-image';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
  ) { }

  getTickets(): Observable<Ticket[]> {
    const url = `${API_URL}/${VERSION}/ticket/tickets`;
    return this.http.get<Ticket[]>(url).pipe(
      map(tickets => {
        this.storageService.set(TICKETS_KEY, tickets);
        return tickets;
      })
    );
  }

  getAllTickets(): Promise<Ticket[]> {
    return this.storageService.get(TICKETS_KEY);
  }

  getTicketById(id: number): Observable<Ticket> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.find(ticket => ticket.id == id))
    );
  }

  getTicketsByWorkplace(companyId: number, workplaceId: number): Observable<Ticket[]> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.filter(ticket => ticket.companyId == companyId && ticket.workplaceId == workplaceId))
    );
  }

  add(ticket: Ticket) {
    const url = `${API_URL}/${VERSION}/ticket/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {
      lastValueFrom(this.http.post(url, ticket)).then(
        response => {
          console.log(response)
        }).catch(
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
        }
      );
    }
    return from(this.addTicket(ticket));
  }

  addImage(ticket: Ticket, images: {name: string, imageBase64: string}[]) {
    const url = `${API_URL}/${VERSION}/Ticket/upload-images`;
    const formData = new FormData();
    for(let image of images) {
      formData.append('files', this.convertToFile(image.imageBase64, image.name));
    }
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', formData);
    } else {
      lastValueFrom(this.http.post(url, formData
      )).then(
        response => {
          //console.log(response)
        }).catch(error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', formData);
        }
      );
    }
    return from(this.addImageByTicketId(ticket.id, images));
  }

  convertToFile(image: string, name: string) {
    const byteCharacters = atob(image.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], name, { type: 'image/jpeg' });
  }

  addTicket(newTicket: Ticket) {
    return this.storageService.get(TICKETS_KEY).then((tickets: any[]) => {
      if (tickets) {
        tickets.unshift(newTicket);
        return this.storageService.set(TICKETS_KEY, tickets);
      }
      return this.storageService.set(TICKETS_KEY, [newTicket]);
    });
  }

  addImageByTicketId(id: string, image: {name: string, imageBase64: string}[]) {
    return this.storageService.get(TICKETS_IMAGE_KEY).then((tickets: {id: string, image: {name: string, imageBase64: string}[]}[]) => {
      if (tickets) {
        tickets.unshift({ id, image });
        return this.storageService.set(TICKETS_IMAGE_KEY, tickets);
      }
      return this.storageService.set(TICKETS_IMAGE_KEY, [{ id, image }]);
    });
  }

  update(ticket: Ticket) {
    const url = `${API_URL}/${VERSION}/ticket/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {
      this.http.post(url, ticket).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
        }
      );
    }
    return from(this.updateTicket(ticket));
  }

  updateTicket(editedTicket: Ticket) {
    return this.storageService.get(TICKETS_KEY).then((tickets: any[]) => {
      const index = tickets.findIndex(tickets => tickets.id === editedTicket.id);
      tickets[index] = editedTicket;
      return this.storageService.set(TICKETS_KEY, tickets);
    });
  }

  delete(ticketId: number) {
    const url = `${API_URL}/${VERSION}/ticket/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticketId);
    } else {
      this.http.post(url, ticketId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticketId);
        }
      );
    }
    return from(this.deleteTicket(ticketId));
  }

  deleteTicket(ticketId: number) {
    return this.storageService.get(TICKETS_KEY).then(tickets => {
      const index = tickets.findIndex(ticket => ticket.id === ticketId);
      tickets.splice(index, 1);
      return this.storageService.set(TICKETS_KEY, tickets);
    });
  }
}
