import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { ServiceSvContract } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const SERVICE_SALE_ORDERS_KEY = 'service-sale-orders';

@Injectable({
  providedIn: 'root'
})
export class ServiceSvContractService {
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) {}

  getServiceSaleOrders(): Observable<ServiceSvContract[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/servicesvcontracts`;
    return this.http.get<ServiceSvContract[]>(url).pipe(
      map(serviceSaleOrders => {
        this.storageService.set(SERVICE_SALE_ORDERS_KEY, serviceSaleOrders);
        return serviceSaleOrders;
      })
    );
  }

  getAllServiceSaleOrders(): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => { 
        const services = serviceSaleOrders.sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))
        
        const servicesGroupByWorkplace = [];                                        
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );;
  }

  getServiceSaleOrdersByDni(dni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => dni && serviceSaleOrder.dni == dni))
    );
  }

  getServiceSaleOrdersBySubstituteDni(dni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => dni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(dni) !== -1))
    );
  }

  getServiceSaleOrderByIds(ids: string[]): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => ids.includes(serviceSaleOrder.serviceSvRef)))
    );
  }

  getServiceSaleOrdersByWorker(dni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => { 
        const services = serviceSaleOrders.filter(serviceSaleOrder => (dni && serviceSaleOrder.dni == dni) || (dni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(dni) !== -1))
                                                .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))
        
        const servicesGroupByWorkplace = [];                                        
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }

  getServiceSaleOrdersByTeamLead(dni: string, ids: string[]): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => {
        const services = serviceSaleOrders.filter(serviceSaleOrder => (dni && serviceSaleOrder.dni == dni) || (dni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(dni) !== -1) || ids.includes(serviceSaleOrder.serviceSvRef))
                                                .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))

        const servicesGroupByWorkplace = [];                                        
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }  

  getServiceSaleOrderByIdAndDni(id: string, dni: string): Observable<ServiceSvContract> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      //map(serviceSaleOrders => serviceSaleOrders.find(serviceSaleOrder => dni && serviceSaleOrder.dni == dni && serviceSaleOrder.id == id))
      map(serviceSaleOrders => serviceSaleOrders.find(serviceSaleOrder => serviceSaleOrder.id == id))
    );
  }

  getServiceSaleOrdersByIdAndDni(id: string, dni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => serviceSaleOrder.id === id))
    );
  }

  getServiceSaleOrderById(id: string): Observable<ServiceSvContract> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.find(serviceSaleOrder => serviceSaleOrder.id == id))
    );
  }

  getServiceSaleOrdersByCompanyId(companyId: number): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => {
        const services = serviceSaleOrders.filter(serviceSaleOrder => serviceSaleOrder.companyId == companyId)
                                              .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))
                            
        const servicesGroupByWorkplace = [];                                        
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }

  getServiceSaleOrdersByWorkplace(companyId: number, workplaceId: number): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SALE_ORDERS_KEY)).pipe(
      map(serviceSaleOrders => serviceSaleOrders.filter(serviceSaleOrder => serviceSaleOrder.companyId == companyId && serviceSaleOrder.workplaceId == workplaceId))
    );
  }

  update(serviceSaleOrder: ServiceSvContract) {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSaleOrder);
    } else {
      this.http.post(url, serviceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSaleOrder);
        }
      );
    }
    return from(this.updateWorkOrder(serviceSaleOrder));
  }
      
  updateWorkOrder(serviceSaleOrder: ServiceSvContract){
    return this.storageService.get(SERVICE_SALE_ORDERS_KEY).then((workOrders: ServiceSvContract[]) => {
      const index = workOrders.findIndex(workOrders => workOrders.id === serviceSaleOrder.id);
      workOrders[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SALE_ORDERS_KEY, workOrders);
    });
  }

  signature(editedServiceSaleOrder: ServiceSvContract) {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/signature`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
    } else {
      this.http.post(url, editedServiceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
        }
      );
    }
    return from(this.signatureServiceSaleOrder(editedServiceSaleOrder));
  }
      
  signatureServiceSaleOrder(serviceSaleOrder: ServiceSvContract){
    return this.storageService.get(SERVICE_SALE_ORDERS_KEY).then((serviceSaleOrders) => {
      const index = serviceSaleOrders.findIndex(serviceSaleOrders => serviceSaleOrders.id === serviceSaleOrder.id);
      serviceSaleOrders[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SALE_ORDERS_KEY, serviceSaleOrders);
    });
  }

  addCpd(serviceSaleOrder: ServiceSvContract) {
    const editedServiceSaleOrder = {...serviceSaleOrder}
    const url = `${API_URL}/${VERSION}/ServiceSvContract/addCpdNum`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
    } else {
      this.http.post(url, editedServiceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSaleOrder);
        }
      );
    }
    return from(this.addCpdNum(editedServiceSaleOrder));
  }
      
  addCpdNum(serviceSaleOrder: ServiceSvContract){
    return this.storageService.get(SERVICE_SALE_ORDERS_KEY).then((serviceSaleOrders) => {
      const index = serviceSaleOrders.findIndex(serviceSaleOrders => serviceSaleOrders.id === serviceSaleOrder.id);
      serviceSaleOrders[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SALE_ORDERS_KEY, serviceSaleOrders);
    });
  }
}
