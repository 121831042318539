import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { importsStandalone } from 'src/app/05-shared/utils/ImportsStandalone';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(public loadingController: LoadingController, private translate: TranslateService) { }

  presentText(text: string) {
    this.presentLoading(text);
  }

  present(key: string, params?: any) {
    this.translate.get(key, params).subscribe(loadingContent => this.presentLoading(loadingContent));
  }

  async presentLoading(msg: string) {
    this.isLoading = true;
    return await this.loadingController.create({
      message: msg,
      spinner: 'lines',
      showBackdrop: true,
      keyboardClose: true,
    }).then(a => {
      a.present().then(() => {
        // //console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => {
            // //console.log('abort presenting')
          });
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {
      // //console.log('dismissed');
    });
  }
}
