import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from 'rxjs';
import { ServiceSvContract, Workplace } from '../01-models';
import { map } from "rxjs/operators";
import { StorageService } from './storage.service';

const WORKPLACES_KEY = 'workplaces';

@Injectable({
  providedIn: 'root'
})
export class WorkplaceService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  getWorkplaces(): Observable<Workplace[]> {
    const url = `${API_URL}/${VERSION}/workplace/workplaces`;
    return this.http.get<Workplace[]>(url).pipe(
      map(workplaces => {
        this.storageService.set(WORKPLACES_KEY, workplaces);
        return workplaces;
      })
    );
  }

  getWorkplacesByCompanyId(companyId: number): Observable<Workplace[]> {
    return from(this.storageService.get(WORKPLACES_KEY)).pipe(
      map((workplaces: Workplace[]) => workplaces.filter(workplace => workplace.companyId == companyId))
    );
  }

  getWorkplacesByWorker(services: ServiceSvContract[]): Observable<Workplace[]> {
    return from(this.storageService.get(WORKPLACES_KEY)).pipe(
      map((workplaces: Workplace[]) => {
        return workplaces.filter(workplace => !!services.find(service => service.companyId === workplace.companyId && service.workplaceId === workplace.workplaceId));
      })
    );
  }

  add(workplace: Workplace) {
    return this.http
      .post(`${API_URL}/${VERSION}/workplace/add`, workplace);
  }

  deleteWorkplace(workplace: Workplace) {
    return this.http
      .post(`${API_URL}/${VERSION}/workplace/delete`, workplace);
  }

  getWorkplace(companyId: number, workplaceId: number): Observable<Workplace | undefined> {
    return from(this.storageService.get(WORKPLACES_KEY)).pipe(
      map((workplaces: Workplace[]) => workplaces.find(workplace => workplace.companyId == companyId && workplace.workplaceId == workplaceId))
    );
  }

  getAllWorkplaces(): Observable<Workplace[] > {
    return from(this.storageService.get(WORKPLACES_KEY));
  }
}
