import { Injectable } from '@angular/core';
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Task } from '../01-models';
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { map } from "rxjs/operators";
import { format, parseISO, addDays, differenceInDays, isBefore, differenceInMilliseconds, intervalToDuration, isSameDay } from 'date-fns';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';

const TASKS_KEY = 'tasks';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService
    ) { }

  scan(newTask: Task) {
    const url = `${API_URL}/${VERSION}/task/scan`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', newTask);
    } else {
      this.http.post(url, newTask).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', newTask);
        }
      );
    }
    return from(this.addTask(newTask));
  }

  addTask(newTask: Task) {
    return this.storageService.get(TASKS_KEY).then((tasks: any[]) => {
      if (tasks) {
        tasks.unshift(newTask);
        return this.storageService.set(TASKS_KEY, tasks);
      }
      return this.storageService.set(TASKS_KEY, [newTask]);
    });
  }

  update(task: Task) {
    const url = `${API_URL}/${VERSION}/task/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', task);
    } else {
      this.http.post(url, task).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', task);
        }
      );
    }
    return from(this.updateTask(task));
  }

  updateTask(editedTask: Task) {
    return this.storageService.get(TASKS_KEY).then((tasks: any[]) => {
      const index = editedTask.id ? tasks.findIndex(task => task.id === editedTask.id) : tasks.findIndex(task => task.tmpId === editedTask.tmpId);
      tasks[index] = editedTask;
      return this.storageService.set(TASKS_KEY, tasks);
    });
  }

  updateStatus(id: number, tmpId: string, status: number, dateTimeTaskDone: Date) {
    const url = `${API_URL}/${VERSION}/task/updateStatus`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', {id: id ? id : tmpId, status, dateTimeTaskDone});
    } else {
      this.http.post(url, {id: id ? id : tmpId, status, dateTimeTaskDone}).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', {id: id ? id : tmpId, status, dateTimeTaskDone});
        }
      );
    }
    return from(this.updateStatusTask(id, tmpId, status, dateTimeTaskDone));
  }

  updateStatusTask(id: number, tmpId: string, status: number, dateTimeTaskDone: Date) {
    return this.storageService.get(TASKS_KEY).then((tasks: any[]) => {
      const index = id ? tasks.findIndex(task => task.id === id) : tasks.findIndex(task => task.tmpId === tmpId);
      tasks[index].status = status;
      tasks[index].dateTimeTaskDone = dateTimeTaskDone;
      return this.storageService.set(TASKS_KEY, tasks);
    });
  }

  getTasks(): Observable<Task[]> {
    const url = `${API_URL}/${VERSION}/task/tasks`;
    return this.http.get<Task[]>(url).pipe(
      map(tasks => {
        this.storageService.set(TASKS_KEY, tasks);
        return tasks;
      })
    );
  }

  getAllTasks(): Observable<Task[]> {
    return from(this.storageService.get(TASKS_KEY));
  }

  getTasksByUserId(userId: number): Observable<Task[]> {
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[]) => tasks.filter(task => task.userId == userId))
    );
  }

  getTasksByDni(dni: string): Observable<Task[]> {
    const currentDate = new Date();
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.filter(task => task.dni === dni))
    );
  }

  getTodayTasksByDni(dni: string): Observable<Task[]> {
    const currentDate = new Date();
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.filter(task => task.dni === dni && 
        (new Date(task.dateTimeTask.toString())).getFullYear() === currentDate.getFullYear() && (new Date(task.dateTimeTask.toString())).getMonth() === currentDate.getMonth() && (new Date(task.dateTimeTask.toString())).getDate() === currentDate.getDate()))
    );
  }

  getTodayTasksByDniAndDate(dni: string, date: Date): Observable<Task[]> {
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.filter(task => task.dni === dni && 
        (new Date(task.dateTimeTask.toString())).getFullYear() === date.getFullYear() && (new Date(task.dateTimeTask.toString())).getMonth() === date.getMonth() && (new Date(task.dateTimeTask.toString())).getDate() === date.getDate()))
    );
  }

  getTodayTasksByWorkplace(companyId: number, workplaceId: number): Observable<Task[]> {
    const currentDate = new Date();
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.filter(task => task.companyId === companyId && task.workplaceId === workplaceId && 
        (new Date(task.dateTimeTask.toString())).getFullYear() === currentDate.getFullYear() && (new Date(task.dateTimeTask.toString())).getMonth() === currentDate.getMonth() && (new Date(task.dateTimeTask.toString())).getDate() === currentDate.getDate()))
    );
  }

  getTasksByWorkplaceAndDate(companyId: number, workplaceId: number, date: Date): Observable<Task[]> {
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.filter(task => task.companyId === companyId && task.workplaceId === workplaceId && 
        (new Date(task.dateTimeTask.toString())).getFullYear() === date.getFullYear() && (new Date(task.dateTimeTask.toString())).getMonth() === date.getMonth() && (new Date(task.dateTimeTask.toString())).getDate() === date.getDate()))
    );
  }

  getTasksByDependenceId(dependenceId: number): Observable<Task[]> {
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.filter(task => task.dependenceId == dependenceId))
    );
  }

  getTaskById(id: number): Observable<Task | undefined> {
    return from(this.storageService.get(TASKS_KEY)).pipe(
      map((tasks: Task[])  => tasks.find(task => task.id == id))
    );
  }

  delete(taskId:number, tmpId:string) {
    const url = `${API_URL}/${VERSION}/task/delete`;
    const id = taskId ? taskId : tmpId;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', JSON.stringify(id));
    } else {
      this.http.post(url, JSON.stringify(id)).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', JSON.stringify(id));
        }
      );
    }
    return from(this.deleteTask(taskId, tmpId));
  }

  deleteTask(taskId: number, tmpId: string) {
    return this.storageService.get(TASKS_KEY).then((tasks: Task[]) => {
      const index = taskId ? tasks.findIndex((task: Task) => task.id === taskId) : tasks.findIndex((task: any) => task.tmpId === tmpId);
      tasks.splice(index, 1);
      return this.storageService.set(TASKS_KEY, tasks);
    });
  }
}
