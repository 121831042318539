export const MINUTE_VALUES = '0,5,10,15,20,25,30,35,40,45,50,55';
export const STATUSES = [{ id: '01', name: 'Registrada por operario' }];


export const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export const MONTH_NAMES_SHORT = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const DAY_NAMES = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];



export const DAY_NAMES_SHORT = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

/**
 * Tareas Ciclo de vida
 * 
 * Verde, Green En Planificación significa que la tarea fué correctamente asignada, pero todavía no fué vista por su responsable o empleado
 *
 * Amarilla Yellow Pendientes INICIO ejecución Planificadas  asignadas
 *
 * Gris,   en ejecución las tareas están detenidas o fueron completadas, con la excepción que estas últimas, también están tachadas
 * Negro,  Black fINALIINAZADA Trabajada significa se ha terminado
 *
 * Naranja,  Warning  Pendiente Atencion tareas están esperando el control del supervisor
 * Rojo,     Danger significa que esa tarea caducó, Atención error  es decir que excedió su fecha límite
 * 
 * Azul,  Aceptada empleado o autorizada
 */
// TODO: Añadir o adaptar estos colores en el logo Kflow ciclo de vida de las tareas
export const TASK_LIFECYCLE = {
  PLANING: '#74d14c', // green
  PENDING: '#e0b500', // yellow
  IN_PROGRESS: '#8b8b8b', // gray 
  WARNING: '#f2af58', // orange
  DANGER: '#f04141', // red
  FINISHED:'#4c96d7',// blue
  INVOICED: '#000000', // black
  CLOSED: '#000000' // black
}


export const PRESENCES_KEY = 'presences';
export const EXPECTED_PRESENCES_KEY = 'expected-presences';
