import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, of } from 'rxjs';
import { OfferService } from './offer.service';
import { IncidenceService } from './incidence.service';
import { ServiceSvContractService } from './service-sv-contract.service';
import { DependenceService } from './dependence.service';
import { UserService } from './user.service';
import { TaskService } from './task.service';
import { ServiceSvService } from './service-sv-extra.service';
import { CpdService } from './cpd.service';
import { TicketService } from './ticket.service';
import { TicketActionService } from './ticket-action.service';
import { RequesterService } from './requester.service';
import { WorkplaceService } from './workplace.service';
import { PresenceService } from './presence.service';
import { ExpectedPresenceService } from './expected-presence.service';
import { NotificationService } from './notification.service';
import { StorageService } from './storage.service';
import { API_URL, VERSION } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { SycnDate } from '../01-models/sycnDate';
import { LoadingService } from './loading.service';
import { TableForSync } from '../01-models/table-for-sync';
import { SyncTable } from '../01-models/sync-table';

const SYNC_KEY = 'sync';

@Injectable({
  providedIn: 'root'
})
export class SynchronizationService {

  syncState$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private offerService = inject(OfferService);
  private serviceSaleOrderService = inject(ServiceSvContractService);
  private dependenceService = inject(DependenceService);
  private userService = inject(UserService);
  private taskService = inject(TaskService);
  private serviceSvService = inject(ServiceSvService);
  private cpdService = inject(CpdService);
  private ticketService = inject(TicketService);
  private ticketActionService = inject(TicketActionService);
  private requesterService = inject(RequesterService);
  private workplaceService = inject(WorkplaceService);
  private presenceService = inject(PresenceService);
  private expectedPresenceService = inject(ExpectedPresenceService);
  private notificationService = inject(NotificationService);
  private storageService = inject(StorageService);
  private http = inject(HttpClient);
  private loading = inject(LoadingService);

  flagLoading = false;


  constructor(
  ) {}

  async sendSyncByTable() {

    let flagSync = false
    const methodByTable = {
      'service-sale-orders': this.serviceSaleOrderService.getServiceSaleOrders(),
      'presences': this.presenceService.getPresences(),
      'expected-presences': this.expectedPresenceService.getExpectedPresences(),
      'dependences': this.dependenceService.getDependences(),
      'cpds': this.cpdService.getCpds(),
      'notifications': this.notificationService.getNotifications(),
      'offers': this.offerService.getOffers(),
      'requesters': this.requesterService.getRequesters(),
      'tasks': this.taskService.getTasks(),
      'tickets': this.ticketService.getTickets(),
      'ticket-actions': this.ticketActionService.getTicketActions(),
      'workplaces': this.workplaceService.getWorkplaces(),
      'work-orders': this.serviceSvService.getWorkOrders(),
    };

    const tablesForSync =  await lastValueFrom(this.syncKflowTablesVerify());
    for (const element of tablesForSync) {
      if (element.mensaje === 'Necesita sincronización') {
        flagSync = true;
        await lastValueFrom(methodByTable[element.tabla]);
      }
    }

    lastValueFrom(this.serviceSvService.getWorkOrdersCategories()).then();
    lastValueFrom(this.userService.getUsers()).then();

    if (flagSync) {
      this.setSyncDate();
    }
  }


  setSyncDate() {
    const currentDateTime = Date.now();
    this.storageService.set(SYNC_KEY, currentDateTime).then(() => {
      this.syncState$.next(currentDateTime);
    });
  }

  public syncKflowTablesVerify(): Observable<TableForSync[]> {
    const date = new Date(this.syncState$.value);
      console.log('Sincronizar desde: ', date);
      const formattedDate = `${date.getFullYear()}-${this.zeroBefore(date.getMonth() + 1)}-${this.zeroBefore(date.getDate())} ${this.zeroBefore(date.getHours())}:${this.zeroBefore(date.getMinutes())}:00`;
      const url = `${API_URL}/${VERSION}/Sync/SyncronizacionKflowTablasResultado`;
      return this.http.post<TableForSync[]>(
        url, 
        new String(formattedDate),
        { headers: { 'Content-Type': 'application/json' } });
  }

  zeroBefore(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }


 
  
}
