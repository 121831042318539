import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom } from "rxjs";
import { ServiceSvExtra } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { WorkOrderCategory } from '../01-models/work-order-category';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { PresenceService } from './presence.service';
import { ExpectedPresenceService } from './expected-presence.service';
import { EXPECTED_PRESENCES_KEY, PRESENCES_KEY } from '../05-shared/utils/Contanst';

const WORK_ORDERS_KEY = 'work-orders';
const WORK_ORDERS_CATEGORIES_KEY = 'work-orders-categories';

@Injectable({
  providedIn: 'root'
})
export class ServiceSvService {
  http = inject(HttpClient);
  networkService = inject(NetworkService);
  offlineManager = inject(OfflineManagerService);
  toastService = inject(ToastService);
  storageService = inject(StorageService);
  constructor(
  ) { }

  getWorkOrders(): Observable<ServiceSvExtra[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/servicessvextra`;
    return this.http.get<ServiceSvExtra[]>(url).pipe(
      map(workOrders => {
        this.storageService.set(WORK_ORDERS_KEY, workOrders);
        return workOrders;
      })
    );
  }

  getAllWorkOrders(): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY));
  }

  getAllExtraWorkOrders(): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.filter(workOrder => workOrder.serviceType === 'Extra')
        .sort((a, b) => ((new Date(a.date.toString())) < (new Date(b.date.toString())) ? 1 : -1) || a.companyName.localeCompare(b.companyName)))
    );
  }

  getWorkOrderById(id: number): Observable<ServiceSvExtra> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.find(workOrder => workOrder.id == id))
    );
  }

  getWorkOrderByRef(ref: string): Observable<ServiceSvExtra> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.find(workOrder => workOrder.serviceSvRef == ref))
    );
  }

  getExtraWorkOrdersByCompanyId(companyId: number): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.filter(workOrder => workOrder.serviceType === 'Extra' && workOrder.companyId === companyId))
    );
  }

  getExtraWorkOrdersByDni(dni: string): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.filter(workOrder => workOrder.serviceType === 'Extra' && ((workOrder.teamLeads && workOrder.teamLeads.indexOf(dni) !== -1) || (workOrder.team && workOrder.team.indexOf(dni) !== -1)))
        .sort((a, b) => ((new Date(a.date.toString())) < (new Date(b.date.toString())) ? 1 : -1) || a.companyName.localeCompare(b.companyName)))
    );
  }

  getContractWorkOrdersByDni(dni: string): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.filter(workOrder => workOrder.serviceType === 'En Contrato' && (workOrder.teamLeads && workOrder.teamLeads.indexOf(dni) !== -1)))
    );
  }

  getWorkOrdersByDni(dni: string): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.filter(workOrder => (workOrder.teamLeads && workOrder.teamLeads.indexOf(dni) !== -1) || (workOrder.team && workOrder.team.indexOf(dni) !== -1)))
    );
  }

  getWorkOrdersByMonth(year: number, month: number): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(WORK_ORDERS_KEY)).pipe(
      map(workOrders => workOrders.filter(workOrder => {
        const date = new Date(workOrder.date);
        return date.getFullYear() === year && date.getMonth() + 1 === month;
      }))
    );
  }

  delete(workOrderId: number) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', workOrderId);
    } else {
      this.http.post(url, workOrderId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', workOrderId);
        }
      );
    }
    return from(this.deleteWorkOrder(workOrderId));
  }

  deleteWorkOrder(workOrderId: number) {
    return this.storageService.get(WORK_ORDERS_KEY).then(workOrders => {
      const index = workOrders.findIndex(workOrder => workOrder.id === workOrderId);
      workOrders.splice(index, 1);
      return this.storageService.set(WORK_ORDERS_KEY, workOrders);
    });
  }

  add(newWorkOrder: ServiceSvExtra): Observable<any> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/add`;
    return this.http.post(url, newWorkOrder);
  }

  addWorkOrder(newWorkOrder: ServiceSvExtra): Observable<ServiceSvExtra> {
    return from(this.storageService.get(WORK_ORDERS_KEY).then((workOrders: ServiceSvExtra[]) => {
      if (workOrders) {
        workOrders.unshift(newWorkOrder);
        return this.storageService.set(WORK_ORDERS_KEY, workOrders);
      }
      return this.storageService.set(WORK_ORDERS_KEY, [newWorkOrder]);
    }));
  }

  update(workOrder: ServiceSvExtra) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', workOrder);
    } else {
      this.http.post(url, workOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', workOrder);
        }
      );
    }
    return from(this.updateWorkOrder(workOrder));
  }

  updateWorkOrder(workOrder: ServiceSvExtra) {
    return this.storageService.get(WORK_ORDERS_KEY).then((workOrders: ServiceSvExtra[]) => {
      const index = workOrders.findIndex(workOrders => workOrders.id === workOrder.id);
      workOrders[index] = workOrder;
      return this.storageService.set(WORK_ORDERS_KEY, workOrders);
    });
  }

  getWorkOrdersCategories(): Observable<WorkOrderCategory[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/categories`;
    return this.http.get<WorkOrderCategory[]>(url).pipe(
      map(workOrdersCategories => {
        this.storageService.set(WORK_ORDERS_CATEGORIES_KEY, workOrdersCategories);
        return workOrdersCategories;
      })
    );
  }

  getWorkOrdersCategoriesFromLocal(): Observable<WorkOrderCategory[]> {
    return from(this.storageService.get(WORK_ORDERS_CATEGORIES_KEY));
  }

  getWorkOrdersCategoriesByServiceId(serviceId: string): Observable<WorkOrderCategory[]> {

    return from(this.storageService.get(WORK_ORDERS_CATEGORIES_KEY)).pipe(
      map(workOrdersCategories => workOrdersCategories.filter(workOrderCategory => workOrderCategory.serviceId === serviceId))
    );
  }

  signature(editedWorkOrder: ServiceSvExtra) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/signature`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedWorkOrder);
    } else {
      this.http.post(url, editedWorkOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedWorkOrder);
        }
      );
    }
    return from(this.signatureWorkOrder(editedWorkOrder));
  }

  signatureWorkOrder(workOrder: ServiceSvExtra) {
    return this.storageService.get(WORK_ORDERS_KEY).then((workOrders) => {
      const index = workOrders.findIndex(workOrders => workOrders.id === workOrder.id);
      workOrders[index] = workOrder;
      return this.storageService.set(WORK_ORDERS_KEY, workOrders);
    });
  }

  async workOrderStatusUpdate(serviceSvRef: string) {
    try {
      const updatedService = await this.serviceSvValidateState(serviceSvRef);
      return this.updateWorkOrder(updatedService).then();
    } catch (error) {
      console.error('Error al actualizar el estado de la orden de trabajo:', error);
      this.toastService.error('Error al actualizar el estado de la orden de trabajo.');
    }
  }


  async serviceSvValidateState(ref: string) {
    try {
      const serviceSv = await lastValueFrom(this.getWorkOrderByRef(ref));
      if (!serviceSv) {
        throw new Error(`No se encontró el servicio con referencia: ${ref}`);
      }
      const presences = await lastValueFrom(this.getPresencesByServiceRef(ref));
      const expectedPresences = await lastValueFrom(this.getExpectedPresencesByServiceRef(ref));

      const totalHoursPresences = presences.reduce((acc, presence) => acc + presence.workedHours, 0);
      const totalHoursExpectedPresences = expectedPresences.reduce((acc, presence) => acc + presence.expectedHours, 0);

      switch (true) {
        case totalHoursPresences < totalHoursExpectedPresences:
          serviceSv.status = '02';
          break;
        case totalHoursPresences > 0 && totalHoursExpectedPresences === 0:
          serviceSv.status = '03';
          break;
        case totalHoursPresences > 0 && totalHoursExpectedPresences === totalHoursPresences:
          serviceSv.status = '03';
          break;
        default:
          serviceSv.status = '01';
          break;
      }

      return serviceSv;
    } catch (error) {
      console.error('Error en serviceSvValidateState:', error);
      throw error;
    }
  }

  getPresencesByServiceRef(serviceSvRef: string) {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceSvRef == serviceSvRef))
    );
  }

  getExpectedPresencesByServiceRef(serviceSvRef: string) {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceSvRef == serviceSvRef))
    );
  }
}
