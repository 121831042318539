import { inject, Injectable } from '@angular/core';
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ExpectedPresence } from '../01-models';
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { map } from "rxjs/operators";
import { format, parseISO, addDays, differenceInDays, isBefore, isSameDay } from 'date-fns';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { ServiceSvService } from './service-sv-extra.service';
import { EXPECTED_PRESENCES_KEY } from '../05-shared/utils/Contanst';

@Injectable({
  providedIn: 'root'
})
export class ExpectedPresenceService {

  http = inject(HttpClient);
  networkService = inject(NetworkService);
  offlineManager = inject(OfflineManagerService);
  toastService = inject(ToastService);
  storageService = inject(StorageService);
  serviceSvService = inject(ServiceSvService);

  constructor(
    ) { }

  getExpectedPresences(): Observable<ExpectedPresence[]> {
    const url = `${API_URL}/${VERSION}/expectedpresence/expectedpresences`;
    return this.http.get<ExpectedPresence[]>(url).pipe(
      map(expectedPresences => {
        this.storageService.set(EXPECTED_PRESENCES_KEY, expectedPresences);
        return expectedPresences;
      })
    );
  }

  getExpectedPresenceByServiceIdAndDniAndDate(serviceId: string, dni: string, date: Date): Observable<ExpectedPresence> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences.find(expectedPresence => expectedPresence.status === 0 && expectedPresence.serviceId === serviceId && expectedPresence.dni === dni && format(parseISO(expectedPresence.startTime), 'dd-MM-yyyy') === format(date, 'dd-MM-yyyy')))
    );
  }

  getExpectedPresenceByServiceIdAndWorkplaceAndDate(serviceId: string, workplaceRef: number, horarioNum: number, date: Date): Observable<ExpectedPresence> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences.find(expectedPresence => expectedPresence.status === 0 && expectedPresence.serviceId === serviceId && expectedPresence.workplaceRef === workplaceRef && expectedPresence.horarioNum === horarioNum && format(parseISO(expectedPresence.startTime), 'dd-MM-yyyy') === format(date, 'dd-MM-yyyy')))
    );
  }

  getExpectedPresencesByDniAndToday(dni: string): Observable<ExpectedPresence[]> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences.filter(expectedPresence => {
        return expectedPresence.status === 0 && expectedPresence.dni === dni && isSameDay(parseISO(expectedPresence.startTime), new Date());
      }))
    );
  }

  getExpectedPresencesByDni(dni: string): Observable<ExpectedPresence[]> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences
        .filter(expectedPresence => expectedPresence.status === 0 && expectedPresence.dni === dni)
        .sort((a, b) => (new Date(a.startTime.toString())) > (new Date(b.startTime.toString())) ? 1 : -1)
        )
    );
  }

  getAllExpectedPresences(): Observable<ExpectedPresence[]> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => {
        if (expectedPresences) {
          return expectedPresences
          .filter(expectedPresence => expectedPresence.status === 0)
          .sort((a, b) => (new Date(a.startTime.toString())) > (new Date(b.startTime.toString())) ? 1 : -1)
        }
      })
      
    );
  }         

  getExpectedPresencesByCompanyIdAndWorkplaceId(companyId: number, workplaceId: number): Observable<ExpectedPresence[]> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences
        .filter(expectedPresence => expectedPresence.status === 0 && expectedPresence.companyId === companyId && expectedPresence.workplaceId === workplaceId)
        .sort((a, b) => (new Date(a.startTime.toString())) > (new Date(b.startTime.toString())) ? 1 : -1)
        )
    );
  }

  getExpectedPresencesByServiceId(serviceId: string): Observable<ExpectedPresence[]> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences
        .filter(expectedPresence => expectedPresence.status === 0 && expectedPresence.serviceId === serviceId)
        .sort((a, b) => (new Date(a.startTime.toString())) > (new Date(b.startTime.toString())) ? 1 : -1)
        )
    );
  }

  getExpectedPresencesByServiceRef(serviceSvRef: string): Observable<ExpectedPresence[]> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresences
        .filter(expectedPresence => expectedPresence.status === 0 && expectedPresence.serviceSvRef === serviceSvRef)
        .sort((a, b) => (new Date(a.startTime.toString())) > (new Date(b.startTime.toString())) ? 1 : -1)
        )
    );
  }

  getExpectedPresenceById(expectedPresenceId: number, tmpId: string): Observable<ExpectedPresence> {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(expectedPresences => expectedPresenceId ? expectedPresences.find(expectedPresence => expectedPresence.id === expectedPresenceId) : expectedPresences.find(expectedPresence => expectedPresence.tmpId === tmpId))
    );
  }

  delete(expectedPresenceId: number, tmpId: string, comments: string) {
    const id = expectedPresenceId ? expectedPresenceId : tmpId;
    const url = `${API_URL}/${VERSION}/expectedpresence/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id:id.toString(), comments });
    } else {
      this.http.post(url, { id:id.toString(), comments }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id:id.toString(), comments });
        }
      );
    }
    return from(this.deletePresence(expectedPresenceId, tmpId, comments));
  }

  deletePresence(expectedPresenceId, tmpId, comments: string) {
    return this.storageService.get(EXPECTED_PRESENCES_KEY).then(async expectedPresences => {
      const index = expectedPresenceId ? expectedPresences.findIndex(expectedPresence => expectedPresence.id === expectedPresenceId) : expectedPresences.findIndex(expectedPresence => expectedPresence.tmpId === tmpId);
      const expected = expectedPresences.splice(index, 1);
      const deleted = await this.storageService.set(EXPECTED_PRESENCES_KEY, expectedPresences);
      await this.serviceSvService.workOrderStatusUpdate(expected[0].serviceSvRef);
      return deleted;
    });
  }
  
  register(expectedPresenceId: number, tmpId: string) {
    return from(this.registerPresence(expectedPresenceId, tmpId));
  }

  registerPresence(expectedPresenceId, tmpId) {
    return this.storageService.get(EXPECTED_PRESENCES_KEY).then(async expectedPresences => {
      const index = expectedPresenceId ? expectedPresences.findIndex(expectedPresence => expectedPresence.id === expectedPresenceId) : expectedPresences.findIndex(expectedPresence => expectedPresence.tmpId === tmpId);
      expectedPresences[index].status = 1;
      const updated = await this.storageService.set(EXPECTED_PRESENCES_KEY, expectedPresences);
      await this.serviceSvService.workOrderStatusUpdate(expectedPresences[index].serviceSvRef);
      return updated;
    });
  }

  recover(expectedPresenceId: number, tmpId: string) {
    return from(this.recoverExpectedPresence(expectedPresenceId, tmpId));
  }

  recoverExpectedPresence(expectedPresenceId, tmpId) {
    return this.storageService.get(EXPECTED_PRESENCES_KEY).then(async (expectedPresences: any[]) => {
      const index = expectedPresenceId ? expectedPresences.findIndex(expectedPresence => expectedPresence.id === expectedPresenceId) : expectedPresences.findIndex(expectedPresence => expectedPresence.tmpId === tmpId);
      expectedPresences[index].status = 0;
      const updated = await this.storageService.set(EXPECTED_PRESENCES_KEY, expectedPresences);
      await this.serviceSvService.workOrderStatusUpdate(expectedPresences[index].serviceSvRef);
      return updated;
    });
  }

  update(expectedPresence) {
    const url = `${API_URL}/${VERSION}/expectedpresence/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', expectedPresence);
    } else {
      this.http.post(url, expectedPresence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', expectedPresence);
        }
      );
    }
    return from(this.updateExpectedPresence(expectedPresence));
  }

  updateExpectedPresence(editedExpectedPresence: ExpectedPresence) {
    return this.storageService.get(EXPECTED_PRESENCES_KEY).then(async (expectedPresences: any[]) => {
      const index = editedExpectedPresence.id ? expectedPresences.findIndex(expectedPresence => expectedPresence.id === editedExpectedPresence.id) : expectedPresences.findIndex(expectedPresence => expectedPresence.tmpId === editedExpectedPresence.tmpId);
      expectedPresences[index] = editedExpectedPresence;
      const updated = await this.storageService.set(EXPECTED_PRESENCES_KEY, expectedPresences);
      await this.serviceSvService.workOrderStatusUpdate(editedExpectedPresence.serviceSvRef);
      return updated;
    });
  }

  add(newPresence) {
    console.log('NewPresence', newPresence);
    const url = `${API_URL}/${VERSION}/expectedpresence/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', newPresence);
    } else {
      this.http.post(url, newPresence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', newPresence);
        }
      );
    }
    return from(this.addExpectedPresence(newPresence));
  }

  addExpectedPresence(newExpectedPresence: ExpectedPresence) {
    return this.storageService.get(EXPECTED_PRESENCES_KEY).then(async (expectedPresences: any[]) => {
      let saved = null;
      if (expectedPresences) {
        expectedPresences.unshift(newExpectedPresence);
        saved = await this.storageService.set(EXPECTED_PRESENCES_KEY, expectedPresences);
      } else {
        saved = await this.storageService.set(EXPECTED_PRESENCES_KEY, [newExpectedPresence]);
      }
      await this.serviceSvService.workOrderStatusUpdate(newExpectedPresence.serviceSvRef);
      return saved;
    });
  }
}
