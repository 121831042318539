import { inject, Injectable } from '@angular/core';
import { API_URL, VERSION } from "../../environments/environment";
import { BehaviorSubject, Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Presence } from '../01-models';
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { finalize, map } from "rxjs/operators";
import { format, parseISO, addDays, differenceInDays, isBefore, differenceInMilliseconds, intervalToDuration, isSameDay } from 'date-fns';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { ServiceSvService } from './service-sv-extra.service';
import { PRESENCES_KEY } from '../05-shared/utils/Contanst';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  updateListPresence = new BehaviorSubject<boolean>(false);

  http = inject(HttpClient);
  networkService = inject(NetworkService);
  offlineManager = inject(OfflineManagerService);
  toastService = inject(ToastService);
  storageService = inject(StorageService);
  serviceSvService = inject(ServiceSvService);

  constructor(
  ) { }

  scan(newPresence) {
    const url = `${API_URL}/${VERSION}/presence/scan`;
    const networkStatus = this.networkService.getCurrentNetworkStatus();
    if (networkStatus == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', newPresence);
    } else {
      this.http.post(url, newPresence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', newPresence);
        }
      );
    }
    return from(this.addPresence(newPresence));
  }

  addPresence(newPresence: Presence) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      let saved = null
      if (presences) {
        presences.unshift(newPresence);
        saved = await this.storageService.set(PRESENCES_KEY, presences);
      } else {
        saved = await this.storageService.set(PRESENCES_KEY, [newPresence]);
      }
      await this.serviceSvService.workOrderStatusUpdate(newPresence.serviceSvRef);
      return saved;
    });
  }

  update(presence) {
    const url = `${API_URL}/${VERSION}/presence/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', presence);
    } else {
      this.http.post(url, presence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', presence);
        }
      );
    }
    return from(this.updatePresence(presence));
  }

  updatePresence(editedPresence: Presence) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = presences.findIndex(presence => presence.tmpId === editedPresence.tmpId);
      presences[index] = editedPresence;
      const presence = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(editedPresence.serviceSvRef);
      return presence;
    });
  }

  getPresences(): Observable<Presence[]> {
    const url = `${API_URL}/${VERSION}/presence/presences`;

    // Obtener fecha de modificacion del ultimo registro
    //const data = this.storageService.get(PRESENCES_KEY).then((presences: any[]) => {

    return this.http.get<Presence[]>(url).pipe(
      map(presences => {
        this.storageService.set(PRESENCES_KEY, presences);
        return presences;
      })
    );
  }

  getAllPresences(): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY));
  }

  getPresencesByUserId(userId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.userId == userId))
    );
  }

  getAllPresencesInprogress(): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => {
        if (presences) {
          return presences
            .filter(presence => !presence.dateTimePresenceEnd)
            .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
        }
      })
    );
  }

  getPresencesInprogressByUserId(userId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.userId == userId && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesInprogressByCompanyIdAndWorkplaceId(companyId: number, workplaceId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.companyId == companyId && presence.workplaceId == workplaceId && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesInprogressByServiceId(serviceId: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceId === serviceId && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesInprogressByServiceRef(serviceSvRef: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceSvRef === serviceSvRef && !presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) > (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getAllPresencesDone(): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => {
        if (presences) {
          return presences
            .filter(presence => presence.dateTimePresenceEnd)
            .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
        }
      })
    );
  }

  getPresencesDoneByUserId(userId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.userId == userId && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByDni(dni: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.dni == dni && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByCompanyIdAndWorkplaceId(companyId: number, workplaceId: number): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.companyId == companyId && presence.workplaceId == workplaceId && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByServiceId(serviceId: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceId == serviceId && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesDoneByServiceRef(serviceSvRef: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences
        .filter(presence => presence.serviceSvRef == serviceSvRef && presence.dateTimePresenceEnd)
        .sort((a, b) => (new Date(a.dateTimePresenceStart.toString())) < (new Date(b.dateTimePresenceStart.toString())) ? 1 : -1)
      )
    );
  }

  getPresencesByServiceRef(serviceSvRef: string): Observable<Presence[]> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceId == serviceSvRef))
    );
  }

  getPresenceById(id: number): Observable<Presence> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.find(presence => presence.id == id))
    );
  }

  getPresenceByTmpId(tmpId: string): Observable<Presence> {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.find(presence => presence.tmpId == tmpId))
    );
  }

  delete(presence, tmpId) {
    const url = `${API_URL}/${VERSION}/presence/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', presence);
    } else {
      this.http.post(url, presence).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', presence);
        }
      );
    }
    return from(this.deletePresence(presence))
  }

  deletePresence(presence) {
    return this.storageService.get(PRESENCES_KEY).then(async presences => {
      const index = presence ? presences.findIndex(p => p.id === presence.id) : presences.findIndex(p => p.tmpId === presence.tmpId);
      presences.splice(index, 1);
      const deleted = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(presences[index].serviceSvRef);
      return deleted;
    });
  }

  updateExit(id: number, tmpId: string, dateTimePresenceEnd: Date) {
    const url = `${API_URL}/${VERSION}/presence/updateExit`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, dateTimePresenceEnd });
    } else {
      this.http.post(url, { id: id ? id : tmpId, dateTimePresenceEnd }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, dateTimePresenceEnd });
        }
      );
    }
    return from(this.updateExitPresence(id, tmpId, dateTimePresenceEnd))
  }

  updateExitPresence(id: number, tmpId: string, dateTimePresenceEnd: Date) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = id ? presences.findIndex(presence => presence.id === id) : presences.findIndex(presence => presence.tmpId === tmpId);
      presences[index].dateTimePresenceEnd = dateTimePresenceEnd;
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(presences[index].serviceSvRef);
      return updated;
    });
  }

  updateDates(id: number, tmpId: string, workedHours: number, dateTimePresenceStart: Date, dateTimePresenceEnd: Date, category: string, registeredHours: number) {
    const url = `${API_URL}/${VERSION}/presence/updateDates`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd });
    } else {
      this.http.post(url, { id: id ? id : tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd, category }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd });
        }
      );
    }
    return from(this.updateDatesPresence(id, tmpId, workedHours, dateTimePresenceStart, dateTimePresenceEnd, category, registeredHours));
  }

  updateDatesPresence(id: number, tmpId: string, workedHours: number, dateTimePresenceStart: Date, dateTimePresenceEnd: Date, category: string, registeredHours: number) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = id ? presences.findIndex(presence => presence.id === id) : presences.findIndex(presence => presence.tmpId === tmpId);
      presences[index].workedHours = workedHours;
      presences[index].registeredHours = registeredHours;
      presences[index].dateTimePresenceStart = dateTimePresenceStart;
      presences[index].dateTimePresenceEnd = dateTimePresenceEnd;
      presences[index].category = category;
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(presences[index].serviceSvRef);
      return updated;
    });
  }

  updateStatus(id: number, tmpId: string, statusId: string) {
    const url = `${API_URL}/${VERSION}/presence/updateStatus`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, statusId });
    } else {
      this.http.post(url, { id: id ? id : tmpId, statusId }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { id: id ? id : tmpId, statusId });
        }
      );
    }
    return from(this.updateStatusPresence(id, tmpId, statusId));
  }

  updateStatusPresence(id: number, tmpId: string, statusId: string) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = id ? presences.findIndex(presence => presence.id === id) : presences.findIndex(presence => presence.tmpId === tmpId);
      presences[index].statusId = statusId;
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(presences[index].serviceSvRef);
      return updated;
    });
  }

  updateCpdNumByService(serviceId: string, cpdNum: number) {
    const url = `${API_URL}/${VERSION}/presence/updateCpdNumByService`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { serviceId, cpdNum });
    } else {
      this.http.post(url, { serviceId, cpdNum }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { serviceId, cpdNum });
        }
      );
    }
    return from(this.updateCpdNumPresencesByService(serviceId, cpdNum));
  }

  updateCpdNumPresencesByService(serviceId: string, cpdNum: number) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = presences.findIndex(presence => presence.serviceId === serviceId);
      const serviceRef = presences[index].serviceSvRef;
      presences.filter(presence => presence.serviceId === serviceId).forEach(presence => {
        presence.cpdNum = cpdNum;
        presence.cpdStatusId = '03';
      });
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(serviceRef);
      return updated;
    });
  }

  updateCpdStatusIdByService(serviceId: string, cpdStatusId: string) {
    const url = `${API_URL}/${VERSION}/presence/updateCpdStatusIdByService`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', { serviceId, cpdStatusId });
    } else {
      this.http.post(url, { serviceId, cpdStatusId }).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', { serviceId, cpdStatusId });
        }
      );
    }
    return from(this.updateCpdStatusIdPresencesByService(serviceId, cpdStatusId));
  }

  updateCpdStatusIdPresencesByService(serviceId: string, cpdStatusId: string) {
    return this.storageService.get(PRESENCES_KEY).then(async (presences: any[]) => {
      const index = presences.findIndex(presence => presence.serviceId === serviceId);
      const serviceRef = presences[index].serviceSvRef;
      presences.filter(presence => presence.serviceId === serviceId).forEach(presence => {
        presence.cpdStatusId = cpdStatusId;
      });
      const updated = await this.storageService.set(PRESENCES_KEY, presences);
      await this.serviceSvService.workOrderStatusUpdate(serviceRef);
      return updated;
    });
  }

  // Método que reutilizarás en los componentes
  isOutOfDate(startTimeString: string): boolean {
    const now = new Date();
    const startTime = new Date(startTimeString);
    
    // Añadir 12 horas a la startTime
    startTime.setHours(startTime.getHours() + 12);
    
    // Registro para verificar los valores
    console.log(`Comparando: startTime ajustada: ${startTime}, now: ${now}`);
    
    return startTime > now;
  }

}
